.side-navigation {

	z-index: 200;

	@include flexbox();
	@include flex-direction(column);
	background-color: $color--mine-shaft;

	&__header {
		background-color: $color--mine-shaft;
	}

	&__filters {
		//view height - logo - search header
		max-height: calc(100vh - 185px);
		overflow-y: auto;
	}
}

.header {

	&__logo {
		height: $navigation-height;
	}

	&__search {
		//height: $header-search-height;
	}
}

.filter {

}


.divider {
	height: 1px;
	border: 0;
	border-top: 1px solid $color--scorpion;
	margin: 0;

	/*&--lead {
		margin: 0;
	}*/
}
