.gray {
	color: #a3a3a3 !important;
}

.black {
    color: #000 !important;
}

.white {
	color: #fff !important;
}

.margin-top {
	margin-top: 10px !important;
}