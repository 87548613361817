//$notification-width: 350px;

.notification-container {
	position: relative;
	//top: calc(50vh + #{$navigation-height} - 150px);
	//right: - $notification-width;
	//width: $notification-width;
	background-color: rgb(255, 72, 39);

	height: 0;
	opacity: 0;
	@include transition(all, $transition-duration--long);
	overflow: hidden;

	&--active {
		opacity: 1;
		height: auto;
		//right: 0;
		@include transition(all, $transition-duration--long);

	}

	.notification {

		padding: 0.5rem 1rem;

		&__error-icon {
			position: absolute;
			right: 0;
			display: inline-block;
			margin-right: 10px;
			top: 10px;
			//background-color: rgb(255, 72, 39);

			@include flexbox();
			@include flex-direction(column);
			@include justify-content(center);
		}

		&__message {
			height: 100%;
			//width: 100%;

			//background-color: lightgrey;
		}

		&__link {
			cursor: pointer;
			text-align: center;
			color: $color--white;

			/*img {
				width: 70%;
			}*/
		}

	}

	.message {
		//@include flexbox();
		//@include flex-direction(column);

		h2 {
			margin: 0 0 4px 0;
			font-size: 18px;
			color: $color--white;
		}

		p {
			font-size: 14px;
			color: $color--white;
			margin: 0;
		}
	}
}
