.top-navigation {
	@include flexbox();
	min-height: $navigation-height;


	&__active-filters,
	selectedfiltersList {
		@include flex-grow(1);
		@include flex-wrap(wrap);
	}

	&__slider {
		@include flex-grow(0);
		width: 250px;
		min-height: inherit;
	}

	&__info {
		@include flex-grow(0);
		z-index: 400;
		//width: 50px;
		padding-right: 20px;

		strong {
			font-size: 13px;
			color: $color--black;
		}
	}

	.info {
		@include flexbox();
		@include flex-direction(column);
		@include justify-content(center);
	}
}
