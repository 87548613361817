.select {
	position: relative;
	width: $navigation-width - 40px;
	-webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
	background-color: transparent;
	background-image:
					linear-gradient(45deg, transparent 50%, $color--white 50%),
					linear-gradient(135deg, $color--white 50%, transparent 50%);
	background-position:
					calc(100% - 12px) calc(1em - 2px),
					calc(100% - 7px) calc(1em - 2px);
	background-size:
					5px 5px,
					5px 5px;
	background-repeat: no-repeat;

	@include transition(background, $transition-duration);
	margin: 3px 0;
	padding: 2px 9px;
	border: 1px solid #fff;
	@include transition(border, $transition-duration);
	border-radius: 3px;
	color: #fff;
	@include transition(color, $transition-duration);
	font-size: 13px;
	cursor: pointer;


	option {
		color: #fff !important;
		background-color: $color--mine-shaft;
		&:hover {
			color: var(--color--accent-1, $color--dusty-gray);
		}
	}
}

.select:hover {
	border: 1px solid var(--color--accent-1, $color--dusty-gray);
	color: var(--color--accent-1, $color--dusty-gray);
	//background: url(../img/arrow_dropdown_green.png) no-repeat 95% center;
	background-image:
					linear-gradient(45deg, transparent 50%, var(--color--accent-1, $color--dusty-gray) 50%),
					linear-gradient(135deg, var(--color--accent-1, $color--dusty-gray) 50%, transparent 50%);
}

.select::-ms-expand {
    display: none; /* hide the default arrow in ie10 and ie11 */
}
