.histogram {

    position: relative;
    padding: 0 10%;
    width: 100;
    // Force aspect ration of 1:1 (which equals the svg's view box)
    padding-bottom: 100%;

    &__chart {
        position: absolute;
        top: 5%;
        left: 5%;
        height: 90%;
        width: 90%;
    }

    &__axis {
        stroke: black;
    }

    &__axisTick {
        stroke: black;
    }

    &__bar {
        fill: #e1e0d5;
        stroke: #1c1c1c;
    }

    &__axisLabel {
        font-size: 14px;
    }

    &__axisLegend {
        font-size: 14px;

        &-y {
            transform: rotate(270deg);
            transform-origin: top left;
        }
    
    }

    &__mic90Line {
        stroke: #aaa897;
        width: 3px;
    }

    &__mic90Text {
        fill: black;
        font-size: 14px;
    }

}

