%triangle {
	content:"";
	position: absolute;
	width: 0;
	height: 0;
	bottom: -10px;
	border-left: 6px solid transparent;
	border-right: 6px solid transparent;
	border-bottom: 6px solid var(--color--accent-1, $color--dusty-gray);
}

.button--icon {
	position: relative;
	width: 26px;
	height: 26px;
	background-repeat: no-repeat;
	background-position: center;
	margin-right: 10px;
	padding: 0;

	svg {
		fill: $color--white;
		height: inherit;
		width: inherit;
		@include transition(fill, $transition-duration);

		&:hover {
			fill: var(--color--accent-1, $color--dusty-gray);

			@at-root {
				.button--icon.button--icon--guidelines svg:hover {
					fill: $color--link-water;
				}
			}
		}
	}

	&--active {
		svg {
			fill: var(--color--accent-1, $color--dusty-gray);

			@at-root {
				.button--icon--active.button--icon--guidelines svg {
					fill: $color--link-water;
				}
			}
		}
		&:after {
			@extend %triangle;
			left: 7px;

			@at-root {
				.button--icon--active.button--icon--guidelines:after {
					border-bottom-color: $color--link-water;
				}
			}
		}
	}

//special cases
	&--population {
		width: 22px;
		&:after {
			left: 5px;
		}
	}

	&--bacteria {
		width: 30px;
		&:after {
			left: 9px;
		}
	}
}
