//BASE
$font-family--sans-serif: "acumin-pro", "Helvetica Neue", Arial;
$line-height-base: 1.6;

$transition-duration: 100ms;
$transition-duration--long: 200ms;

$group__title-h1:	18px;
$group__title-h2:	12px;
$group__title-h3:	14px;

$font-size: 18px;
$font-size--small: 16px;
$font-size--guided-tour: 16px;
$line-height--guided-tour: 1.35;


//COLORS
/* FABIAN: #f5d3b3 for Vets? */

// Set basic colors.
$color--white:              #FFFFFF;
$color--gallery:            #eee;
$color--gray:               #888;
$color--silver:             #ccc;
$color--dove-gray:          #616161;
$color--tundora:            #444;
$color--mine-shaft:         #363636;
$color--scorpion:           #575757;
$color--black:              #000000;


// (Yellowish) grays
$color--ash:                #c5c3b2;
$color--moon-mist:          #d7d5c4;
$color--quill-gray:         #d3d2cd;
$color--satin-linen:        #e1e0d5;
$color--cararra:            #f5f5ef;
$color--ecru-white:         #fcfcf7;

// (Yellowish) greens == accent colors – use neutral (tenant-independant) tones to check if
// everything is overwritten by tenant colors.
$color--dusty-gray:         #bbbbbb; // == --color--accent-1
$color--silver:             #999999; // == --color--accent-2


// Blues
$color--cornflower-blue:    #578ffa;
$color--curious-blue:       #3289CC;
$color--indigo:             #4e79c7;
$color--link-water:         #D8E7F4;
$color--spindle:            #b9d5ed;
$color--jelly-bean:         #2b78b3;

// Reds (for errors)
$color--guardsman-red:      #d60000;

// Derived colors
$color--text:  $color--black;


// Buttons
$button-height: 38px;
//$button-bg-color: #c5c3b2;
//$button-bg-color--hover: #d7d5c4;


//TOP NAVIGATION
$navigation-height: 				70px;

$navigation-button--label-size:		14px;
$navigation-button--font-size:		26px;


//SIDE NAVIGATION
$navigation-min-width: 				210px;
$navigation-width:					210px;
$sidenavigation__group__title-size:	26px;
$search-box--height:				70px;
$header-search-height:				128px;



//RESPONSIVE
$breakpoint-mobile: 800px;

$breakpoint-xs: 375px;
$breakpoint-sm: 480px;
$breakpoint-md: 736px;
$breakpoint-lg: 980px;
$breakpoint-xl: 1280px;
