// opener
.open-diagnosis-drawer-button {
  @include flexbox();
  @include flex-direction(column);
  @include justify-content(center);
  height: 100%;
  margin-right: 1.25rem;
}


.drawer {

  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 480px;
  z-index: 1000;
  /* Move to the left as teaser is not part of the drawer itself, but attached to the left side
     of it */
  box-shadow: -20px 0 25px rgba(0, 0, 0, 0.25);
  transform: translate3d(0, 0, 0);
  transition: transform 0.4s cubic-bezier(0.645, 0.045, 0.355, 1), box-shadow 0.4s;

  &.hidden {
    transform: translate3d(480px, 0, 0);
    box-shadow: none;
  }

  &.is-empty {
    // Hide instantly as content will disappear when drawer content is reset to undefined
    transition: none;
  }

  &__inner {
    clear: right;
    padding-bottom: 100px;
    height: 100%;
    @include flexbox();
    @include flex-direction(column);

    h1 {
      font-size: 24px;
      color: #fff;
      max-width: 85%;
      line-height: 1.1;
      margin: 0 3rem 0 2rem;
    }
    /*h2 {
      font-size: 20px;
      line-height: 1.1;
      margin: 0;
    }*/

    p {
      margin: 0 0 8px 0;
      font-size: 14px;
      line-height: 1.45;
    }

  }


  &.hidden {
    .drawer__teaser {
      cursor: w-resize;
    }

    // Make line wider when drawer is hidden
    .drawer__teaser:after {
      width: 10PX;
    }
  }

  // Teaser
  &__teaser {
    cursor: e-resize;
    position: absolute;
    top: 0;
    right: 100%;
    height: 100%;
    bottom: 0;
    z-index: 999;

    &:hover {
      &:after {
        background-color: darken($color--jelly-bean, 7%);
      }

      .drawer__teaser-flap {
        background-color: darken($color--jelly-bean, 7%);
      }
    }

    &:after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      width: 2PX;
      height: 100%;
      background-color: $color--jelly-bean;
      transition: background-color $transition-duration, width $transition-duration;
    }


    &-flap {
      position: absolute;
      top: 15%;
      right: 0;
      background-color: $color--jelly-bean;
      padding: 20px 20px 20px 10px;
      border-radius: 5px;
      color: $color--white;
      @include transition(background-color, $transition-duration);
      // Width: (15px * 1.2 lineHeight) + (18px * 1.2 line-height)
      width: 40px;
    }

    &-subtitle {
      font-size: 15px;
      line-height: 1.2;
      writing-mode: vertical-rl;
      text-orientation: mixed;
      @include transform(rotate(180deg));
      display: inline;
    }
    &-title {
      font-size: 18px;
      font-weight: bold;
      line-height: 1.2;
      writing-mode: vertical-rl;
      text-orientation: mixed;
      @include transform(rotate(180deg));
      display: inline;
    }
  }

  &__fixed {
    padding: 20px 30px;
    @include flex(0 0 auto);
    background-color: $color--jelly-bean;
  }
  &__scrollable {
    @include flex(1 1 auto);
    overflow: auto;
    @include scroll-indicator($color--link-water);
  }
  &__scrollable-inner {
    padding-bottom: 60px;

    // disclaimer only
    > .markdown {
      margin: 30px 0;

      p {
        font-size: 13px;
        color: #575757;
      }
    }
  }


  &__header {
    background-color: $color--spindle;
    padding: 20px 30px;

    &-inner {
      margin-left: 2rem;

      &-link {
        color: $color--jelly-bean;
        cursor: pointer;

        &:hover {
          color: $color--jelly-bean;
          text-decoration: underline;
        }
      }
    }
  }

  &__therapies {

    padding: 20px 30px;

    &-inner {
      margin-left: 2rem;
    }
  }

  &.resistance {

    .button--close-drawer {
      color: $color--text;

      &:hover {
        background-color: $color--cararra;
      }
    }

    .drawer {
      &__teaser {
        &::after {
          background-color: $color--satin-linen;
        }

        &-flap {
          color: $color--text;
          background-color: $color--satin-linen;
        }
      }

      &__scrollable {
        @include scroll-indicator($color--ecru-white);
      }

      &__fixed {
        background-color: $color--satin-linen;

        h1 {
          margin: 0 1rem 0 0;
          max-width: 90%;
          color: $color--text;
        }
      }

      &__section {
        padding: 22px 30px;

        &--mic,
        &--selectedFilters,
        &--discDiffusion {
          padding-bottom: 0;
        }

        h2 {
          font-size: 17px;
          line-height: 1.1;
          margin: 0 0 13px 0;
        }

        ul {
          padding-left: 0;
          list-style-type: '–';
          list-style-position: inside;
        }

        li {
          font-size: 14px;
          line-height: 1.45;    

          span {
            padding-left: 8px;
          }
        }
      }

      &__header {
        padding: 56px 30px 22px;
        background-color: transparent;
      }

      &__values {
        font-size: 14px;
      }

      &__value {
        &--bold {
          font-weight: bold;
        }

        &-value {
          padding-left: 24px;
        }
      }
    }
  }

}


.diagnosis-text {

  margin-bottom: 40px;

  &:last-child {
    margin-bottom: 0;
  }

  &__choose-title { // styling for "wahl"-titles
    position: relative;
    font-size: 17px;
    line-height: 1.2;
    margin: 0 0 6px -2rem;
    color: $color--jelly-bean;

    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 1.5rem;
      height: 1.5rem;
      border-radius: 100%;
      background-color: $color--jelly-bean;
    }

  }
  &__choose-title-number {
    position: relative;
    color: #fff;
    margin-left: 0;
    line-height: 1.5;
    width: 1.5rem;
    display: inline-block;
    text-align: center;
    margin-right: 0.5rem;
  }
}


.diagnosis-general-considerations {
  border-top: 1px solid $color--jelly-bean;
  padding: 20px 30px;

  &__inner {
    margin-left: 2rem;

    h2 {
      font-size: 17px;
      line-height: 1.2;
      margin: 0 0 6px 0;
    }
  }
}

.diagnosis-additional-informations {
  border-top: 1px solid $color--jelly-bean;
  padding: 20px 30px;

  > div {
    margin-left: 2rem;
    font-size: 14px;
  }

  &__guideline-link,
  &__contact {
    a {
      color: $color--jelly-bean;
      font-weight: bold;
      text-decoration: underline;

      &:hover {
        text-decoration: none;
      }
    }
  }

  &__latest-version {
    margin-top: 20px;

    color: $color--tundora;
    font-size: 13px;

    a {
      color: inherit;
      text-decoration: underline;

      &:hover {
        text-decoration: none;
      }
    }
  }

}



.markdown {

  color: #000;


  h3 {
    font-size: 17px;
    line-height: 1.1;
    margin: 24px 0 8px 0;
  }
  // first child in markdown follows directly after to a h2 – reduce top space
  h3:first-child {
    margin-top: 16px;
  }
  h4,
  h5,
  h6 {
    font-size: 15.5px;
    line-height: 1.1;
    margin: 20px 0 8px 0;
  }

  a {
    color: black;
    text-decoration: underline;
  }

  p,
  li {
    font-size: 14px;
    line-height: 1.45;
  }

  p {
    margin: 16px 0 4px 0;
  }

  h3 + p,
  h4 + p,
  h5 + p,
  h6 + p {
    margin-top: 4px;
  }

  li {
    position: relative;
    margin: 0 0 4px 0;
  }

  ul {
    margin: 0;
    padding-left: 1.25rem;
    list-style-type: none;

    > li {
      &:before {
        position: absolute;
        left: 0;
        color: $color--gray;
        content: '\2014';
        margin-left: -1.25rem;
      }

      > ul {

        margin-top: 0.25rem;
        margin-left: 0;
        padding-left: 0.75rem;

        > li {
          &:before {
            position: absolute;
            left: 0;
            color: $color--gray;
            content: '\2013';
            margin-left: -0.75rem;
          }
        }
      }
    }

  }

  pre,
  code {
    font-family: inherit;
    font-size: 14px;
    line-height: 1.35;
  }

  // drawer disclaimer
  &.drawer-disclaimer {
    color: #444444;

    p {
      font-size: 13px;
      margin-bottom: 0;
      color: inherit;
    }

    a {
      font-size: 13px;
      color: $color--jelly-bean;
      text-decoration: underline;

      &:hover {
        text-decoration: none;
      }
    }
  }
}
