infooverlaybutton {
	display: block;
}

.overlay {
	//position: fixed;
	position: absolute;
	display: none;
	right: 0;
	bottom: 0;
	top: 0;
	left: 0;
	z-index: -1;
	overflow: auto;

	@include css3-prefix(hyphens, auto);

	background-color: $color--dove-gray;
	// Must be above transition so that overlay does not fade out
	// when site is loading.
	opacity: 0;
	@include transition(opacity, $transition-duration);

	&--open {
		z-index: 3000;
		opacity: 1;
		display: block;

		.button--info {
			position: fixed !important;
		}
	}

	&__menu {
		display: none;

		@include min-screen($breakpoint-mobile) {
			display: block;
			position: fixed;
			right: 35px;
			top: 100px;
			z-index: 200;
		}

		.menu {
			list-style: none;

			&-item {
				padding: 5px 0;
				text-align: right;

				a {
					position: relative;
					//text-transform: uppercase;

					color: $color--white;

					&:hover,
					&:active {
						@include transition(font-weight, $transition-duration);
						font-weight: 600;
					}
				}
			}
		}
	}

	&__container {
		position: relative;
		width: 80%;
		max-width: 600px;
		padding: $navigation-height 0 200px 0;
		margin: auto;
		color: $color--white;

		@include min-screen($breakpoint-mobile) {
			width: 40%;
		}
	}


	// step images wrapper
	&__image-wrapper {


		&--step2 {
			padding: 20px 40px;

			@include min-screen($breakpoint-sm) {
				padding: 40px 80px;
			}
			@include min-screen($breakpoint-md) {
				padding: 60px 120px;
			}
			@include min-screen($breakpoint-mobile) {
				padding: 20px 40px;
			}
			@include min-screen($breakpoint-lg) {
				padding: 40px 80px;
			}
			@include min-screen($breakpoint-xl) {
				padding: 80px 140px;
			}
		}
		&--step3 {
			padding: 20px 40px;

			@include min-screen($breakpoint-sm) {
				padding: 40px 80px;
			}
			@include min-screen($breakpoint-md) {
				padding: 60px 120px;
			}
			@include min-screen($breakpoint-mobile) {
				padding: 20px 40px;
			}
			@include min-screen($breakpoint-lg) {
				padding: 40px 80px;
			}
			@include min-screen($breakpoint-xl) {
				padding: 80px 140px;
			}
		}
	}
}


.overlay-markdown {

	color: $color--white;

	h1 {
		margin-top: 1.3em;
		margin-bottom: 0em;
	}

	h2 {
		margin-top: 1.3em;
		margin-bottom: 0em;
	}

	p {
		small {
			font-size: 0.8em;
		}
	}

	a {
		@include transition(color, $transition-duration, ease-in-out);
		color: var(--color--accent-1, $color--silver);

		&:hover {
			//color: var(--color--accent-2, $color--dusty-gray);
			text-decoration: underline;
		}
	}

	// store links
	a[href*="apple"],
	a[href*="google"] {
		display: inline-block;

		img {
			display: block;
			margin-top: 1rem;
			margin-right: 0.625rem;
			height: 3rem;
			border: none !important;
			background-color: transparent !important;
		}

		+ br {
			display: none;
		}
	}


	// specials/local images
	img[src*="/tenant/"] {
		margin-top: 1rem;
		border-style: solid;
		border-color: $color--white;
		background-color: $color--white;
		border-width: 20px 40px;
		@include css3-prefix(box-sizing, border-box);
		max-width: 100%;

		@include min-screen($breakpoint-sm) {
			border-width: 40px 80px;
		}
		@include min-screen($breakpoint-md) {
			border-width: 60px 120px;
		}
		@include min-screen($breakpoint-mobile) {
			border-width: 20px 40px;
		}
		@include min-screen($breakpoint-lg) {
			border-width: 40px 80px;
		}
		@include min-screen($breakpoint-xl) {
			border-width: 80px 140px;
		}
	}

	img[src*="map.svg"] {
		border: none !important;
	}

	// tourguide button
	a[data-guided-tour-button] {
		border: none;
		font-weight: 600;
		@include border-radius(5px);
		padding: 5px 15px;

		color: #fff;
		background-color: $color--cornflower-blue;
		@include transition(background-color, $transition-duration);

		&:hover {
			text-decoration: none;
			background-color: $color--indigo;
		}
	}

	ul {
		margin-left: 0.25rem;
		padding-left: 1rem;
		list-style-type: "—";

		li {
			padding-left: 20px;
		}

	}


	.adresses {
		@include flexbox();
		@include flex-direction(row);
		@include flex-wrap(wrap);
		@include justify-content(space-between);

		address {
			@include flex-basis(40%);
			margin-bottom: 5%;
		}
	}

	.image--fullwidth {
		display: block;
		width: 100%;
	}

	.guide {
		margin: 40px 0;
		font-weight: 600;

		button {
			//margin-left: 40px;
		}
	}

}
