//general
.list-item--checkbox,
.list-item--radio {
  margin-left: 20px;

  input[type="checkbox"],
  input[type="radio"] {
    display: none;
  }

  .side-label {
  //  display: block;
    position: relative;
    cursor: pointer;
    //color: #fff;
    color: inherit;
    @include transition(color, $transition-duration);
  }

  .side-label::before, .side-label::after {
    content: '';
    position: absolute;
    top: 6px;
    left: -20px;
  }
}

//checkbox
.list-item--checkbox {
  //unchecked
  .side-label::before {
    display: block;
    width: 10px;
    height: 10px;
    border: 1px solid;
    border-color: $color--white;
    @include transition(border-color, $transition-duration);
  }

  //checked
  .side-label::after {
    //display: none;
    width: 6px;
    height: 6px;
    margin: 3px;
    background-color: none;
    @include transition(background-color, $transition-duration);
  }

  input:checked + .side-label::after {
    //display: block;
    background-color: var(--color--accent-1, $color--dusty-gray);
  }

  input:hover + .side-label
  ,input:hover + .side-label::before
  ,input:checked + .side-label
  ,input:checked + .side-label::before {
    color: var(--color--accent-1, $color--dusty-gray);
    border-color: var(--color--accent-1, $color--dusty-gray);
  }
  input:hover + .side-label::after {
    background-color: rgba(var(--color--accent-1, $color--dusty-gray), 0.2);
  }
  input:checked:hover + .side-label::after {
    background-color: var(--color--accent-1, $color--dusty-gray);
  }
}

//radio
.list-item--radio {
  .side-label::after {
    top: 4px;
    width: 12px;
    height: 12px;
    border: 1px solid;
    border-color: $color--black;
    @include border-radius(7px);
    @include transition(all, $transition-duration);
  }

  //radio active
  .side-label::before {
    //display: none;
    z-index: 2;
    top: 4px;
    width: 6px;
    height: 6px;
    margin: 4px;
    //background-color: $sidenavigation-background;
    background-color: transparent;
    @include border-radius(5px);
    @include transition(background-color, $transition-duration);
  }

  input[type="checkbox"]:checked + .side-label::before {
    background-color: $color--mine-shaft;
  }
  input[type="checkbox"]:checked + .side-label::after {
    background-color: var(--color--accent-1, $color--dusty-gray);
  }
  input[type="checkbox"]:hover + .side-label
  ,input[type="checkbox"]:hover + .side-label::after
  ,input[type="checkbox"]:checked + .side-label
  ,input[type="checkbox"]:checked + .side-label::after {
    color: var(--color--accent-1, $color--dusty-gray);
    border-color: var(--color--accent-1, $color--dusty-gray);
  }


  input[type="radio"]:checked + .side-label::before {
    background-color: $color--mine-shaft;
  }
  /*input[type="radio"]:checked + .side-label::after {
    background-color: $active__checkbox-color;
  }*/
  /*input[type="radio"]:hover + .side-label
  ,input[type="radio"]:hover + .side-label::after
  ,input[type="radio"]:checked + .side-label
  ,input[type="radio"]:checked + .side-label::after {
    color: $active__checkbox-color;
    border-color: $active__checkbox-color;
  }*/
  input[type="radio"]:hover + .side-label::before {
    background-color: rgba(0, 0, 0, 0.2);
  }
  input[type="radio"]:checked:hover + .side-label::before {
    background-color: #000;
  }

}


/**
 * Bright input element on dark background (animal filters for VET)
 */
.list-item--radio.list-item--bright {
  .side-label::after {
    border-color: $color--white;
  }  
  
  input[type="radio"]:checked + .side-label::before {
    background-color: $color--white;
  }
}
