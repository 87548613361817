@mixin scroll-indicator($background-color) {
    background:
        /* Shadow covers */
        linear-gradient($background-color 30%, rgba($background-color,0)),
        linear-gradient(rgba($background-color,0), $background-color 70%) 0 100%,

            /* Shadows */
        radial-gradient(50% 0, farthest-side, rgba(0,0,0,.2), rgba(0,0,0,0)),
        radial-gradient(50% 100%,farthest-side, rgba(0,0,0,.2), rgba(0,0,0,0)) 0 100%;
    background:
        /* Shadow covers */
        linear-gradient($background-color 30%, rgba(255,255,255,0)),
        linear-gradient(rgba(255,255,255,0), $background-color 70%) 0 100%,

            /* Shadows */
        radial-gradient(farthest-side at 50% 0, rgba(0,0,0,.2), rgba(0,0,0,0)),
        radial-gradient(farthest-side at 50% 100%, rgba(0, 0, 0, 0.2), rgba(0,0,0,0)) 0 100%;
    background-repeat: no-repeat;
    background-color: $background-color;
    background-size: 100% 40px, 100% 40px, 100% 14px, 100% 14px;

    /* Opera doesn't support this in the shorthand */
    background-attachment: local, local, scroll, scroll;
}
