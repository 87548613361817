.slider {
	font-size: 11pt;
	text-align: center;

	&__range {
		padding: 0;

		&::-webkit-slider-thumb {
			background-color: var(--color--accent-1, $color--dusty-gray);
		}

	}

	&__label {
		margin: 0;
		padding-top: 15px;
	}

	&__container {
		@include flexbox();
		@include flex-direction(row);
		@include justify-content(center);
		align-items: center;

		&__before {
			margin-right: 5px;
		}

		&__range{
			@include flex-grow(1);
		}

		&__after {
			margin-left: 5px;
		}
	}
}

