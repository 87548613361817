// Custom elements are display:inline if not specified otherwise, which
// interferes with the svg's with css rule.
matrix,
matrixheader {
    display: block;
}



.resistanceMatrix {

    position: relative;
    z-index: 100;

    /** @type {Number} Slow down all animations (for debugging purposes) */
    $slowdown-factor: 1;


    /*
    * Only display transitions and custom visibility style when and after the first
    * filter is set
    */
    .-with-transitions {

        /**
        * General modifers used for antibiotics, bacteria, substanceClasses and resistances
        * (animations/transition)
        */
        .-was-visible-is-visible {
            /* Wait until newly invisibles were faded out, then move */
            transition: transform calc(#{$slowdown-factor} * 0.3s) ease-in-out calc(#{$slowdown-factor} * 0.1s)
        }

        .-was-hidden-is-visible {
            /* Only fade in after
                a) newly hiddens were hidden and
                b) (unchanged) visibles were moved */
            transition: opacity calc(#{$slowdown-factor} * 0.1s) ease-in-out calc(#{$slowdown-factor} * 0.4s);
        }

        .-was-visible-is-hidden {
            /* Fade out tutswitt to not block or slow down UI */
            transition: opacity calc(#{$slowdown-factor} * 0.1s) ease-in-out;
        }

        .-was-hidden-is-hidden,
        .-was-visible-is-hidden {
            opacity: 0;
            pointer-events: none;
        }

    }



    &__overlay {
        //position: fixed;
        position: absolute;
        /*width: 100%;
        height: 100%;
        top: $navigation-height;
        left: $navigation-width;*/
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: $color--dove-gray;
        @include transition(opacity, $transition-duration);
        opacity: 0;
        z-index: -1;

        @include flexbox();
        @include flex-direction(column);
        @include justify-content(center);

        img {
            margin: auto;
            width: 150px;
            pointer-events: none;
        }

        &--active {
            opacity: 0.8;
            z-index: 200;
        }
    }



    &__body,
    &__header {
        text-rendering: optimizeLegibility;
        -webkit-font-smoothing: antialiased;

        max-width:2200px; /* or circles are too large */
        min-width: 1500px; /* or circles are smaller than the font */
        width: 100%;
        font-size: 11pt;
        font-family: "acumin-pro-condensed";
    }

    &__body {
        position: relative;
        z-index: 50;
        transition: height calc(#{$slowdown-factor} * 0.3s) ease-in-out 0.2s;
    }

    &__header {
        position: fixed;
        z-index: 90;
    }

    &__bacteriumLabelText {
        text-anchor: end;
        font-style: italic;

        &.-causes-diagnosis {
            fill: $color--jelly-bean;
        }

        &.-deactivated {
            fill: $color--silver;
        }

    }

    &__resistance {
        cursor: pointer;
        // Use visiblity: hidden to hide resistances (for speed reasons)
        // and ignore pointer-events on them
        //pointer-events: visible;
    }

    &__substanceClassLine {

        &--top {

        }
    }

    &__antibioticLabelText,
    &__bacteriumLabelText {

        transition: fill 0.2s;

        &.highlight {
            text-decoration: underline;
        }

        &.containsGuidelineData {
            fill: $color--curious-blue;
        }

    }

    &__antibioticLabelGuidelinePriorityCircle {
        /* FABIAAAAAAN! Use variable or something */
        fill: $color--curious-blue;
    }

    &__antibioticLabelGuidelinePriorityText {
        /* FABIAAAAAAN! Number of therapy priority */
        fill: $color--white;
        font-family: $font-family--sans-serif;
        font-weight: bold;
    }

    &__substanceClassLabel {
        cursor: pointer;

        &:hover {
            text {
                fill: $color--white;
            }
            rect {
                fill: #{$color--mine-shaft};
            }
        }
    }

    /**
    * Resistance Detail (Hover)
    */
    &__resistanceDetail {
        transition: transform 0.2s;
        // Don't let the <g> cause mouseLeaves on the underlying resistance
        pointer-events: none;
    }

    &__resistanceDetailValueText {
        font-size: 18pt;
        text-decoration: underline;
    }

    &__resistanceDetailValuePercentSign {
        font-size: 11pt;
    }

    &__resistanceDetailCircle {
    }

    &__resistanceDetailCircleShadow {
        fill: $color--black;
        opacity: 0.4;
    }

    &__antibioticColumnHighlightedBackground,
    &__bacteriumRowHighlightedBackground {
        transition: opacity 0.1s ease-in;
    }


}
