html {
	font-size: 16px;
	-webkit-font-smoothing: antialiased;
	-webkit-text-size-adjust: 100%;

	-moz-osx-font-smoothing: grayscale;

    -ms-touch-action: none !important;

	text-rendering: optimizeLegibility;
	font-feature-settings: "kern";
	-webkit-font-feature-settings: "kern";
	-moz-font-feature-settings: "kern";
	-moz-font-feature-settings: "kern=1";

	//@include css3-prefix(hyphens, auto);
}

body {
	position: fixed;
	height: 100%;
	width: 100%;
	margin: 0;
	overflow: hidden;
	-webkit-overflow-scrolling: touch;

	font-family: $font-family--sans-serif;
	line-height: 1.65;
	color: $color--black;
	background-color: $color--white;
	font-size: 18px;
}


// Reset fonts for relevant elements
input,
button,
select,
textarea {
	font-family: inherit;
	font-size: inherit;
	line-height: inherit;

	&:active,
	&:focus {
		outline: none;
	}
}



button {
	background: none;
    cursor: pointer;
}

// Links
a {
	color: var(--color--accent-2, $color--silver);
	text-decoration: none;

	&:hover {
		color: var(--color--accent-1, $color--dusty-gray);
		text-decoration: none;
		outline: none;
	}

	&:focus,
	&:active {
		color: inherit;
		text-decoration: none;
		outline: none;
	}
}

//Scrollbar
::-webkit-scrollbar {
	background-color: transparent;
	width: 5px;
	height: 5px;
}

::-webkit-scrollbar-button {

}

::-webkit-scrollbar-track {

}

::-webkit-scrollbar-track-piece {
	background-color: transparent;
}

::-webkit-scrollbar-thumb {
	background-color: rgba(153,153,153,0.5);
	border-radius: 10px;
}
::-webkit-scrollbar-corner       { /* 6 */ }
::-webkit-resizer                { /* 7 */ }


address {
	font-style: normal;
}


.image {
	display: block;

	&--fluid {
		width: 100%;
	}
}
