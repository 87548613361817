.container {
	@include flexbox();
	@include flex-direction(row);

	height: 100%;

	&__side-navigation {
		@include flex-grow(0);
		width: $navigation-width;//20vw;
		min-width: $navigation-min-width;
	}

	&__main {
		height: 100%;
		width: calc(100vw - #{$navigation-width});
	}
}


.main {
	@include flexbox();
	@include flex-direction(column);

	&__header {
		flex: auto;
		border-bottom: solid 1px $color--quill-gray;
	}

	&__disclaimer {
		flex: auto;
	}

	&__matrix {
		@include flex-grow(1);
		width: auto;
		height: 100%;
		overflow: auto;
		//margin-top: 20px;
		padding-left: 20px;
	}
}
