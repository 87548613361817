.c-logo {
	@include flexbox();
	@include justify-content(left);
	padding-left: 20px;
	align-items: center;
	background-color: var(--color--accent-1, $color--dusty-gray);

	&__link {
		z-index: 5;
		height: $navigation-height - 20px;
	}

	&__vector-image {
		width: auto;
		height: 100%;
		max-width: $navigation-width - 20px; // - 20px = padding-left of logo

		&--hidden {
			display: none;
		}

		/* TODO: FEEEEEELIX, you may wanna remove this */
		* {
			fill: $color--mine-shaft !important;
		}
	}
}
