.button {
  border: none;
  color: $color--black;
  font-weight: 600;

  //used to center x
  /*span {
    position: relative;
    top: -2px;
  }*/

  &--label {
    position: relative;

    min-height: $button-height;
    margin: 0;
    padding: 0 20px;
    @media (pointer: fine) {
      padding: 0 8px;
    }

    background-color: $color--ash;
    font-weight: bold;
    @include border-radius-edges(0, 5px, 5px, 0);


    // guideline specific styling
    @at-root {
      .list-item--guidelines {
        .button--label {
          background-color: $color--spindle;
        }
      }
    }


    &:hover {
      background-color: $color--guardsman-red;
      color: #fff;
    }

    $hover-hover: hover;
    @include hover-interaction($hover-hover) {
      padding: 0 10px !important;
    }
  }

  &--clearall {
    height: $button-height;
    background-color: $color--ash;
    @include border-radius(5px);
    width: $button-height;
    font-weight: bold;

    &:hover {
      background-color: $color--guardsman-red;
      color: $color--white;
    }
  }

  &--info {
    position: relative;
    //@include transition(background-color color, $transition-duration);
    color: $color--black;
    padding: 0;
    margin: 0;
    //width: 36px;
    //height: 36px;
    //@include border-radius(18px);

    > div {
      position: relative;
      background-color: $color--gallery;
      @include border-radius-edges(5px, 5px, 5px, 5px);
      @include flexbox();
      @include flex-wrap(nowrap);
      @include transition(background-color, $transition-duration);

      &:hover {
        background-color: darken($color--gallery, 5%);
      }

      span {
        &:first-child {
          @include flex(0 0 auto);
          width: 30px;
          color: #000;
          line-height: 32px;
          padding-left: 0.25rem;
          font-weight: bold;
          font-size: 18px;
        }
        &:nth-child(2) {
          @include flex(1 1 auto);
          padding: 0 1rem 0 0.25rem;
          white-space: nowrap;
          font-size: 16px;
          font-weight: bold;
          line-height: 34px;

          em {
            font-size: 15px;
            font-style: normal;
          }
        }
      }
    }

    /*&:hover {
      background-color: $navigation-background--lighter;
    }*/

    // overlay opened
    &-active {

      position: absolute;
      top: 1.25rem;
      right: 1.25rem;

      > span {
        display: block;
        border-radius: 50%;
        width: 30px;
        height: 30px;
        line-height: 30px;
        background-color: $color--white;
        color: $color--black;
        @include transition(background-color, $transition-duration);

        &:hover {
          background-color: lightgrey;
        }
      }

    }
  }

  &--show-guideline {
    position: relative;
    @include transition(background-color, $transition-duration);
    background-color: $color--link-water;
    @include border-radius-edges(5px, 5px, 5px, 5px);
    padding: 0 1rem 0 0.25rem;
    white-space: nowrap;
    font-size: 16px;
    font-weight: bold;
    line-height: 34px;

    &:hover {
      background-color: darken($color--link-water, 5%);
    }

    svg {
      width: 2.25rem;
      height: 1rem;
    }
  }


  &--close-drawer {
    position: absolute;
    right: 1.25rem;
    top: 1rem;
    width: 2rem;
    height: 2rem;
    padding-bottom: 4px;
    border-radius: 100%;
    line-height: 10px;
    @include transition(background-color, $transition-duration);
    //background-color: darken($guidelines-color, 5%);
    background-color: $color--white;
    color: $color--jelly-bean;

    &:hover {
      background-color: $color--link-water;
    }
  }

  &--guide,
  &--show-relevant-guideline-information {
    @include border-radius(5px);
    padding: 5px 15px;

    color: #fff;
    background-color: $color--jelly-bean;
    @include transition(background-color, $transition-duration);

    &:hover {
      background-color: $color--indigo;
    }
  }

  &--show-relevant-guideline-information {
    margin-bottom: 1.25rem;
  }
}
