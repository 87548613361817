$padding-lr: 30px;

.search {
	position: absolute;
	display: block;
	left: -$navigation-width;
	@include transition(left, $transition-duration, ease-in-out);
	top: $search-box--height + 20px;
	z-index: 99;
	padding: 0;
	width: $navigation-width;
	height: 100vw;
	background-color: $color--mine-shaft;

	&--visible {
		left: 0;
	}

	&__input {
		line-height: 20px;
		margin-bottom: 30px;
		font-size: 11pt;
		@include css3-prefix(appearance, none);
        border: 0;
	}

	&__result {

	}

	&-wrapper {
		position: relative;
	}


}

.result--active.result--guideline {
	background-color: $color--spindle;
}

.result.result--guideline:hover {
	background-color: $color--spindle;
}

.result {
	position: relative;
	padding: 10px $padding-lr;
	margin: 0;
	background-color: $color--moon-mist;

	&--guideline {
		background-color: $color--link-water;
	}

	&__item {
    	text-overflow: ellipsis;
    	width: 150px;
    	display: block;
    	overflow: hidden
	}

	&__checkmark {
		display: block;
		position: absolute;
		left: 10px;
		width: 15px;
		height: 40px;
		top: calc(50% - 20px);
		background: url('../img/checkmark--grey.svg') no-repeat center center;

	}

	&--active {
		background-color: $color--ash;
	}

	&:hover {
		background-color: $color--ash;
		cursor: pointer;

		.result__checkmark {
			background: url('../img/checkmark.svg') no-repeat center center;
		}
	}
}
