disclaimer {
	position: fixed;
	left: 0;
	bottom: 0;
	width: 100%;
	z-index: 1500;
	box-shadow: 0 -10px 20px rgba(0, 0, 0, 0.15);
}
.disclaimer {
	position: relative;

	box-sizing: border-box;
	padding: 12px 16px;

	background-color: rgba($color--gallery, 0.9);
	color: #000;
	font-size: $font-size--small;

	text-align: center;

	&__paragraph {
		margin: 0;
		padding-right: 20px;
		@include clearfix();
	}

	&__text {
		margin-right: 20px;

		&--guide-info {
			/*display: none;

			@include min-screen('1000px') {
				display: inline;
			}*/
		}
	}

	&__button-container {
		display: inline-block;
		margin-right: 10px;

		&--guide {
			/*display: none;

			@include min-screen('1000px') {
				display: inline-block;
			}*/
		}

		&--close {
			position: absolute;
			right: 0;
			top: 10px;
		}
	}

	&__button {
		color: black !important;
		cursor: pointer;
	}

	&__link {
		color: inherit;
		text-decoration: underline;
		cursor: pointer;

		&:focus,
		&:hover {
			color: inherit;
		}

		&--disclaimer {
			font-weight: 800;
		}

	}



}
